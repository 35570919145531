import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Messages from 'views/provider/messages';
import AccountSettings from 'views/provider/accountsettings';
import ExpansionCard from 'views/healthrecords/appointments/appointmentdetails/ExpansionCard';
import DietCard from 'views/provider/patients/tabs/tabcontents/dreams/plans/activities/diet/DietCard';
import PlansTab from 'views/provider/patients/tabs/tabcontents/dreams/plans/PlansTab';

const ProviderDashboard = Loadable(lazy(() => import('views/provider/dashboard')));
const Tasks = Loadable(lazy(() => import('views/tasks')));
const Patients = Loadable(lazy(() => import('views/provider/patients')));
const Reports = Loadable(lazy(() => import('views/provider/reports')));
const OrganizationSettings = Loadable(lazy(() => import('views/provider/organizationsettings')));
const PatientSearchDetails = Loadable(lazy(() => import('views/provider/patients/MainContents')));
const ReportContainer = Loadable(lazy(() => import('views/provider/organizationsettings/tabs/reports/ReportContainer')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <ProviderDashboard />
        },
        {
            path: '/dashboard',

            element: <ProviderDashboard />
        },
        {
            path: '/patients',

            element: <Patients />
        },
        {
            path: '/tasks',

            element: <Tasks />
        },
        {
            path: '/patients/appointmentdetails',
            element: <ExpansionCard />
        },
        {
            path: '/patients/patientdetails',

            element: <PatientSearchDetails />
        },
        {
            path: '/messages',
            element: <Messages />
        },
        {
            path: '/reports',

            element: <ReportContainer />
        },
        {
            path: '/organizationsettings',

            element: <OrganizationSettings />
        },
        {
            path: '/accountSettings',
            element: <AccountSettings />
        },
        {
            path: '/dreamsplan/diet',
            element: <DietCard />
        }
    ]
};

export default MainRoutes;

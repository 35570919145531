import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery, Grid } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

import { ReactComponent as SutterHealth } from '../../assets/images/logos/sutter_health_logo.svg';

// assets
import { IconChevronRight } from '@tabler/icons';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // styles
    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter
            }),
            [theme.breakpoints.up('md')]: {
                // marginLeft: -(drawerWidth - 20),
                marginLeft: -drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: -drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`
                // padding: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                // marginLeft: -drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                marginTop: '150px'
                // padding: '16px'
                // marginRight: '10px'
            }
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            }),
            marginLeft: matchDownMd ? -drawerWidth : 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down('md')]: {
                // marginLeft: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '150px'
                // marginLeft: '10px'
            }
        })
    }));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                {header}
                <Box sx={{ my: 1, display: { sm: 'none', xs: 'block' }, pr: 2 }} display="flex" alignItems="center">
                    <Grid container spacing={1} alignItems="center" sx={{ justifyContent: 'center' }}>
                        <Grid item>
                            <SutterHealth />
                        </Grid>
                        <Grid item>Sutter Health</Grid>
                    </Grid>
                </Box>
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main theme={theme} open={drawerOpen}>
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg">
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </>
                )}
            </Main>
        </Box>
    );
};

export default MainLayout;

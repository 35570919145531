// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconDashboard,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers
} from '@tabler/icons';

import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TaskIcon from '@mui/icons-material/Task';
// constant
const icons = {
    ManageAccountsIcon,
    ArticleIcon,
    GroupIcon,
    DashboardIcon,
    ApartmentIcon,
    VolunteerActivismIcon,
    IconBrandChrome,
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers,
    TaskIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'patients',
            title: <FormattedMessage id="Patients" />,
            type: 'item',
            url: '/patients',
            icon: icons.GroupIcon,
            breadcrumbs: false
        },
        {
            id: 'tasks',
            title: 'Tasks',
            type: 'item',
            url: '/tasks',
            icon: icons.TaskIcon,
            breadcrumbs: false
        },
        {
            id: 'messages',
            title: <FormattedMessage id="Messages" />,
            type: 'item',
            url: '/messages',
            icon: icons.IconMail,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: <FormattedMessage id="Reports" />,
            type: 'item',
            url: '/reports',
            icon: icons.ArticleIcon,
            breadcrumbs: false
        },
        {
            id: 'organizationsettings',
            title: <FormattedMessage id="Organization_Settings" />,
            type: 'item',
            url: '/organizationsettings',
            icon: icons.ApartmentIcon,
            breadcrumbs: false
        },
        {
            id: 'accountsettings',
            title: <FormattedMessage id="Account_Settings" />,
            type: 'item',
            url: '/accountsettings',
            icon: icons.ManageAccountsIcon,
            breadcrumbs: false
        }
    ]
};

export default other;
